import React, { useState, useEffect } from "react"
import { Gallery, GalleryImage } from "react-gesture-gallery"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import "./carousel.css"

const images = [
  {
    src:
      "https://res.cloudinary.com/dpcbyznazf/image/upload/w_600,f_auto/v1577840483/happy-child.jpg",
    alt: "Child Dentistry",
  },
  {
    src:
      "https://res.cloudinary.com/dpcbyznazf/image/upload/w_600,f_auto/v1577840706/smiling-young-couple_lvkms5.jpg",
    alt: "Happy Adult Dentistry",
  },
  {
    src:
      "https://res.cloudinary.com/dpcbyznazf/image/upload/w_600,f_auto/v1577841331/smiling-couple_zjaaby.jpg",
    alt: "Happy Elderly Dentistry",
  },
]

const Carousel = props => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      if (index === 2) {
        setIndex(0)
      } else {
        setIndex(prev => prev + 1)
      }
    }, 3000)
    return () => clearInterval(timer)
  }, [index])

  return (
    <Gallery
      className={"carousel"}
      style={{
        margin: "auto",
        height: "50vh",
        width: "50vw",
      }}
      index={index}
      onRequestChange={i => {
        setIndex(i)
      }}
      enableControls={false}
      enableIndicators={false}
    >
      {images.map(image => (
        <GalleryImage
          objectFit="contain"
          key={image}
          src={image.src}
          alt={image.alt}
        />
      ))}
    </Gallery>
  )
}

export default Carousel
