import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
import "./index.css"
import tooth from "../images/tooth-icon-white@2x.png"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Welcome" />
    <div className="container">
      <div className="banner">
        <h3>
          We are your friendly, comprehensive dental solution for your entire
          family.
        </h3>
        <Carousel 
          className="slider" 
          images={
            [data.general.childImageSharp.fluid, data.toothbrushImage.childImageSharp.fluid  ]
          }/>
        <div className="tooth-divider-white">
          <img src={tooth} width="45px" alt="tooth-logo" />
        </div>
        <h1>Orchard Park NY Dentist</h1>
        <p>
          We are a third generation dental practice that was opened by Dr. Ben
          Clauss's grandfather Dr. Joseph G. Clauss Sr. Our family has been
          taking care of the families of Western New York since 1953. We pride
          ourselves in building a warm and friendly relationship with our
          patients as well as providing you with highly efficient and
          professional service.
        </p>
      </div>
      <div className="featured-block-left">
        {" "}
        <div className="featured-content">
          <h2 className="headline">Why Choose us?</h2>
          <p className="rich-text">
            We perform honest, high-quality dentistry at a reasonable price. Our
            goal is to work with you to create individualized solutions by
            listening to your concerns and educating you on your options. We
            never want you to feel like you're being sold a list of goods or
            pressured into making decisions before you are ready.
          </p>
        </div>
        <div className="featured-image">
          <Img
            fluid={data.toothbrushImage.childImageSharp.fluid}
            alt="why-choose-us"
          />
        </div>
      </div>
      <div className="featured-block-right">
        {" "}
        <div className="featured-content">
          <h2 className="headline">Treatment Solutions</h2>
          <em>
            Every mouth is different – so every solution should be, too. We’ll
            work with you to find the dental solution that's right for you.
          </em>
          <ul className="treatment-solutions-list">
            <li>
              <Link to="treatment-solutions#preventative-solutions">
                Preventative Solutions
              </Link>
            </li>
            <li>
              <Link to="treatment-solutions#general-solutions">
                General Dental Solutions
              </Link>
            </li>
            <li>
              <Link to="treatment-solutions#fixed-solutions">
                Fixed Solutions
              </Link>
            </li>
            <li>
              <Link to="treatment-solutions#removable-solutions">
                Removable Solutions
              </Link>
            </li>
          </ul>
        </div>
        <div className="featured-image">
          <Img
            fluid={data.general.childImageSharp.fluid}
            alt="treatment-solutions"
          />
        </div>
      </div>

      <div className="client-reviews">
        <h2>What our patients are saying</h2>
        <blockquote>
          Appreciate Dr. Clauss' calm and thorough approach. He takes the time
          to explain why a procedure is necessary or why one might wait. His
          hygienists are the best - they are gentle and don't yell at me for not
          flossing :)
        </blockquote>
        <em>Erin Bowman</em>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    toothbrushImage: file(relativePath: { eq: "toothbrush-variation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    general: file(relativePath: { eq: "general.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
